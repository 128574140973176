.OrderChoice {
  display: flex;
}
.DirectOrderButton {
  background-color: lightskyblue;
  height: 5em;
  width: 35%;
  margin: auto;
  text-align: center;
}
.ShopOrderButton {
  background-color: mediumseagreen;
  height: 5em;
  width: 35%;
  margin: auto;
  text-align: center;
}
