.DirectOrder {
  display: inline;
}

.ContactInfo {
  margin-left: 10%;
  float: left;
  display: flexbox;
}

.ContactInfo li {
  text-align: left;
  display: block;
}

.ContactInfo h3 {
  display: block;
}
