.mainbody {
  background-color: rgb(252, 252, 252);
}

.MainView {
  margin-left: 5%;
  margin-right: 5%;
}

.MainView ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}

.MainView li {
  display: inline-block;
  margin: 20px;
}

.MainView a {
  text-decoration: none;
  color: black;
}

.MainView a:hover,
.MainView a:active,
.MainView a.active {
  color: #fa923f;
}

header {
  background-color: white;
}

.version-number {
  position: relative;
  left: 90%;
}