.OrderTable {
  width: 100%;
  background-color: white;
  
}

.OrderTable tr {
  border-bottom: 1px solid #b5b4b4;
}

.Submitted,
.Submitted a {
  background-color: lightpink;
  color: white;
}

.Ordered {
  background-color: palegoldenrod;
}

.Backorder,
.Backorder a {
  background-color: maroon;
  color: white;
}

.ReceivedShop {
  background-color: cornflowerblue;
}

.ReceivedField {
  background-color: lightseagreen;
}

.Canceled {
  background-color: maroon;
  color: white;
}
