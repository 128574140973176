.NewOrderForm {
  display: flexbox;
  margin-left: 20%;
}

.NewOrderForm input,
.NewOrderForm select,
.NewOrderForm textarea,
.NewOrderForm button {
  display: block;
}
