.OrderTimeLine {
  display: block;
  margin-bottom: 10px;
}

.OrderTimeLine input {
  margin-right: 20%;
}

.OrderTimeLine p {
  display: inline;
  text-align: left;
}

.Completed {
  background-color: lightblue;
}
