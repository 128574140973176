.issueSection {
    background-color: orange;
}

.TableContainer {
    margin: 20px 0;
    border-radius: 5px;
    padding: 10px 0px;
    box-shadow: 0px 0px 5px 0px #bdbcbc;
}

.TableContainer h2 { 
    padding: .75rem 1.25rem;
}

.TableContainer td button { 
    margin: 0 5px;
}

.materialRequestContainer {
    background-color: #fff3cd;
    border-color: #ffecb5;
}

.issueContainer {
    background-color: rgb(255, 95, 95);
    border-color: rgb(250, 54, 54);
}

.purchaseOrderContainer {
    background-color: rgb(224, 222, 222);
    border-color: rgb(146, 146, 146);
}