.DetailedOrderTable {
  width: 100%;
  background-color: white;
  
}

.DetailedOrderTable tr {
  border-bottom: 1px solid #b5b4b4;
}

.sortIcon:hover {
  opacity:1;
}

.sortIcon {
  opacity: 0.5;
}