.unselectedButton {
    border-radius: .25rem;
    color: #000000;
    padding: .375rem .75rem;
    margin: 0 0.3rem;
    vertical-align: middle;
    background-color: #ECECEC;
    border: solid #AEAEAE 1px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    text-align: center;
 }

 .unselectedButton:hover {
    background: #DEDEDE;
    border: solid #7C7C7C 1px;
    text-decoration: none;
 }

 .selectedButton {
    border-radius: .25rem;
    color: #FFFFFF;
    padding: .375rem .75rem;
    margin: 0 0.3rem;
    vertical-align: middle;
    background-color: #00339A;
    border: solid #5a57ff 1px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    text-align: center;
 }